@import "../../styles/variables.scss";

// public web apps
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 0;
  border-top: 1px solid $grey10;
  border-bottom: 1px solid $grey10;
  padding: 5px 0;

  .btn {
    border: none;
  }

  .pages {
    font-size: $caption;
    margin: 0;
  }
}

// internal web apps
ul.pagination {
  border: none;
  margin: 0;

  .page-item {
    .page-link {
      font-weight: $font_weight_semibold;
      color: $brand1;
      text-decoration: none;
      text-transform: uppercase;
      min-width: 40px;
      height: 40px;
      border-radius: 4px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $grey3;
      }

      &[disabled] {
        &:hover {
          background-color: initial;
        }
        .mdi {
          background-color: $grey30;
        }
      }
    }

    .mdi {
      background-color: $brand1;
    }

    &.disabled {
      .mdi {
        background-color: $grey30;
      }
    }
    &.active {
      .page-link {
        background-color: $brand3_tint;

        &:hover {
          background-color: $grey3;
        }
      }
    }
    &.pages-summary {
      .page-link {
        color: $grey70;
      }
    }
  }
}
