@import "../../styles/variables.scss";

.badge {
  border-radius: 4px;
  background-color: $grey10;
  text-transform: uppercase;
  padding: 3px 5px;
  font-size: $tiny;
  font-weight: $font_weight_semibold;
  font-family: $font_family_normal;
  color: $grey90;
  line-height: 15px;
  height: 20px;
  display: inline-flex;
  align-items: center;

  &.badge-info {
    background-color: $info_tint;
    color: $grey70;
  }
}
