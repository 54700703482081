// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@font-face {
  font-family: "Roboto-Light";
  src: url("../assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  // unicode-range: U+0020-2212;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  // unicode-range: U+0020-2074;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
