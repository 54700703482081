@import "../../styles/variables.scss";

.form-group {
  margin-bottom: 15px;
  color: $grey90;

  .form-control {
    height: $form_control_height;
    border: 1px solid $grey10;
    box-shadow: none;
    font-size: $form_control_font_size;

    &.disabled, &[disabled] {
      background-color: $grey5;
    }
  }

  label {
    display: flex;
    align-items: center;
    font-size: $body;
    font-weight: $font_weight_semibold;
    font-family: $font_family_normal;
    line-height: 1.5;
    color: $brand1;
    width: 100%;
    margin-bottom: 5px;
    white-space: nowrap;
  }

  .form-text {
    font-size: $caption;
  }
}

.input-group {
  .input-group-prepend {
    .mdi {
      color: $brand3;
    }

    .input-group-text {
      padding: 0.2rem 0.5rem;
      background-color: $white;
      border: 1px solid $grey10;
      border-right: none;
    }
  }

  .form-control {
    border-left: none;
    padding-left: 0px;
  }

  &.is-invalid {
    .input-group-prepend > .input-group-text {
      border-color: $error;
    }

    .form-control {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-color: $error;
    }
  }
}

.input-group.addon-right {
  .form-control {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid $grey10;
    padding-left: 12px;

    &:focus {
      border-left-color: $brand1;
    }

    &.is-invalid {
      border-left-color: $error;
    }
  }

  .input-group-addon {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 3;
  }
}

.invalid-feedback {
  margin-top: 0.25rem;
  display: block;
  color: $error;
  font-size: $form_control_error_font_size;

  .media {
    align-items: center;
  }

  .mdi {
    background-color: $error;
  }
}

.hint-feedback {
  margin-top: 0.25rem;
  display: block;
  color: $grey90;
  font-size: $form_control_error_font_size;

  .media {
    align-items: center;
  }

  .mdi {
    background-color: $info;
  }
}

.form-control {
  font-size: $body;
  height: $form_control_height;

  &:focus {
    border-color: $brand1;
  }

  &.is-invalid {
    border-color: $error;
    padding-right: 0.75rem;
    background-image: none;
  }
  &.hint {
    border-color: $info;
    padding-right: 0.75rem;
    background-image: none;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $grey30;
    opacity: 1; // Firefox
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $grey30;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $grey30;
  }
}

.is-invalid {
  .form-control {
    border-color: $error;
    padding-right: 0.75rem;
    background-image: none;
  }
}

.hint {
  .form-control {
    border-color: $info;
    padding-right: 0.75rem;
    background-image: none;
  }
}

select.form-control {
  max-width: 100%;
  background: url("../../assets/icons/chevron-down.svg") right center no-repeat !important;
  background-position-x: calc(100% - 7px);

  line-height: 16px !important;
  appearance: none;
  padding-right: 25px;

  /* for IE and Edge */
  &::-ms-expand {
    display: none;
  }
}

.label-required {
  font-size: $caption;
}

.form-control-with-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .mdi {
    position: absolute;
    left: 7px;
    color: $brand3;
    pointer-events: none;

    &.right {
      left: auto;
      right: 10px;
    }
  }

  .btn {
    position: absolute;
    right: 3px;
    height: 34px;
    width: 34px;
    padding: 0;
    border: none;

    .mdi {
      color: $brand1;
      position: initial;
    }
  }

  .form-control {
    padding-left: 30px;
  }

  &.textarea {
    .mdi {
      top: 5px;
    }

    .form-control {
      margin: 0;
      min-height: 40px;
      height: auto;
      line-height: 25px;
    }
  }

  &.disabled {
    .mdi {
      background-color: $grey30;
    }
    .form-control {
      color: $grey30;
    }
  }
}

.form-control-native {
  .mdi {
    z-index: 1;
  }

  .form-control {
    position: relative;
    color: transparent;

    &:before {
      content: attr(title);
      display: inline-block;
      color: $grey90;
      vertical-align: sub;
    }

    &::-webkit-datetime-edit,
    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      display: none;
    }

    // https://stackoverflow.com/questions/51334960/how-to-show-calendar-popup-when-inputtype-date-is-on-focus
    &::-webkit-calendar-picker-indicator {
      position: absolute;
      opacity: 1;
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      width: auto;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}
