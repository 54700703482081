@import "variables";

.list-group.options {
  display: flex;
  flex-direction: column;
  margin: 0;

  .list-group-item {
    display: flex;
    align-items: center;
    height: 42px;
    cursor: pointer;
    width: 100%;
    padding: 10px 10px;
    z-index: 0;
    border-radius: 0;

    position: relative;
    background-color: $white;
    border: 1px solid $grey10;

    &.active {
      color: $grey90;
      background-color: $brand3_tint;
      border-color: $grey10;
    }

    &:hover {
      color: $grey90;
      background-color: $grey3;
      border-color: $grey10;
    }
    
    .payment-cards {
      height: 20px;
    }
    &.pay-pal-account {
      color: $grey90;
      padding: 10px 0 10px 30px;
      height: auto;

      &:hover {
        background-color: transparent;
      }

      .btn-link {
        cursor: pointer;
        height: auto;
        padding: 0 15px;
      }
    }
  }

  .separator {
    text-align: center;
    padding: 10px 15px;

    &:before,
    &:after {
      display: inline-block;
      content: "";
      border-top: 1px solid $grey10;
      width: 60px;
      height: 1px;
      margin: 0 10px;
      transform: translateY(-4px);
    }
  }

  .sublist {
    background-color: $grey3;
    padding: 15px;

    .sublist--title {
        margin-bottom: 10px;
        font-size: $body;
        font-weight: $font_weight_semibold;
        font-family: $font_family_semibold;
    }
    
    .list-group {
      margin: 0;
    }
  }

}
