@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../styles/variables.scss";

header {
  width: 100%;
  z-index: 10;

  &.stick {
    position: fixed;
    top: 0;
  }

  a {
    text-decoration: none;
  }

  .jayride-logo {
    width: 100px;
    height: 50px;

    @include media-breakpoint-up(md) {
      width: 176px;
      height: 80px;
    }
  }

  .mdi-chevron-down-outline,
  .mdi-chevron-up-outline {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }

  .super-header {
    background-color: $grey10;
    color: $grey50;
    height: 32px;
    padding: 2px 15px;
    font-size: $caption;
    display: flex;
    justify-content: end;
    align-items: center;

    .mdi {
      background-color: $grey50;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .navbar {
    background-color: $white;
    box-shadow: $shadow_small;
    padding: 0 12px;
    margin: 8px;
    border-radius: 4px;

    @include media-breakpoint-up(md) {
      margin: 0;
      border-radius: 0;
    }

    .btn-tertiary {
      color: $btn_primary;
      font-weight: $font_weight_semibold;
      font-size: $body;
      line-height: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;

      .mdi {
        background-color: $btn_primary;
      }


      &:hover {
        color: $btn_primary_hover;
      }

      &:active {
        color: $btn_primary_pressed;
      }

      &:focus {
        color: $btn_primary_focus;
      }
    }

    .navbar-toggler {
      border: none;
      margin-left: -12px;

      &.navbar-toggler-right {
        margin: 0 -12px 0 0;
      }
    }

    .navbar-collapse {
      justify-content: end;
      transition: all 0.1s linear;

      &.show {
        padding: 24px;

        .nav-item {
          padding: 0 10px;

          .nav-link {
            font-weight: $font_weight_normal;
            font-size: $body_reskin;
            text-transform: capitalize;

            &.tint {
              font-weight: $font_weight_lighter;
            }
          }
        }
      }
    }

    .nav-item {
      height: 54px;

      @include media-breakpoint-up(md) {
        height: 80px;
      }

      align-items: center;
      display: flex;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;

      &:active {
        background: $brand3_tint;
        color: $brand1;

        @include media-breakpoint-up(md) {
          background: inherit;
          color: inherit;
          border-bottom: 3px solid $brand3;
        }
      }

      &.ml {
        margin-left: 16px;

        @include media-breakpoint-up(md) {
          margin-left: 8px;
        }
      }

      .nav-link {
        width: 100%;
        color: $btn_primary;
        font-weight: $font_weight_semibold;

        @include media-breakpoint-up(md) {
          padding: 16px;
        }

        font-size: $body;
        line-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;

        .mdi {
          background-color: $btn_primary;
        }

        &:hover {
          color: $btn_primary_hover;
        }

        &:active {
          color: $btn_primary_pressed;
        }

        &:focus {
          color: $btn_primary_focus;
        }

        &.dropdown-toggle::after {
          -webkit-mask-image: url("../../assets/icons/chevron-down-outline.svg");
          mask-image: url("../../assets/icons/chevron-down-outline.svg");
        }

        &.highlight {
          background-color: #A0E8FF; // $brand3_tint;
          border-radius: 4px;
          padding: 8px 20px;
        }

        &.tint {
          font-weight: 300;
        }
      }

      &.show.dropdown {
        background-color: $white;
        border-bottom: 1px solid $grey10;
        border-top: none;
        right: 0;

        @include media-breakpoint-up(md) {
          border-bottom: 3px solid transparent;
          border-top: 3px solid transparent;
        }

        .dropdown-toggle::after {
          -webkit-mask-image: url("../../assets/icons/chevron-up-outline.svg");
          mask-image: url("../../assets/icons/chevron-up-outline.svg");
        }
      }

      .dropdown-toggle::after {
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center;
        mask-position: center;
        content: " ";
        display: inline-block;
        height: 20px;
        width: 20px;
        background-color: $btn_primary;
      }

      .dropdown-menu {
        margin: 0;
        padding: 24px;
        border: none;
        border-radius: 0 0 4px 4px;
        box-shadow: $shadow_medium;
        background-color: $white;

        &.align-right {
          position: absolute;
          left: auto !important;
          right: 0 !important;
        }

        .dropdown-item {
          padding: 12px 20px;
          font-size: $body_reskin;
          font-weight: $font_weight_normal;
          height: 44px;
          color: $btn_primary;
          line-height: 20px;

          &.tint {
            font-weight: 300;
          }

          &:hover {
            background-color: $grey3;
          }

          &:active {
            background-color: $grey5;
          }

          &.active {
            background: $brand3_tint;
          }
        }

        .dropdown-divider {
          margin: 12px 0;
        }
      }
    }
  }
}