@import "../../styles/variables.scss";

.extra-luggage,
.fixed-price,
.refund-guarantee,
.safe-and-secure {
  display: inline-block;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
}

.safe-and-secure {
  background: url(../../assets/icons/why-book/pictogram-safe-and-secure.svg);
}

.extra-luggage {
  background: url(../../assets/icons/why-book/pictogram-extra-luggage.svg);
}

.fixed-price {
  background: url(../../assets/icons/why-book/pictogram-fixed-price.svg);
}

.refund-guarantee {
  background: url(../../assets/icons/why-book/pictogram-guarantee.svg);
}

.braintree {
  background: url(../../assets/icons/why-book/braintree.svg);
  background-repeat: no-repeat;
  display: inline-block;
  width: 83px;
  height: 20px;
}

.widget {

  // why book on jayride
  .media {
    .media-body {
      h5 {
        font-size: $body;
        font-weight: $font_weight_semibold;
        font-family: $font_family_semibold;
        margin-top: 0;
        margin-bottom: 5px;
      }

      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }

  // faqs
  .accordion {
    padding-bottom: 10px;

    .btn-min {
      min-width: 40px;
    }

    .accordion--header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      cursor: pointer;

      .accordion--title {
        margin: 0;
        font-size: $body;
        font-weight: $font_weight_semibold;
        font-family: $font_family_semibold;
        line-height: 20px;
      }
    }

    .accordion--body {
      padding-right: 45px;
    }
  }

}

// faqs (bootstrap layout https://getbootstrap.com/docs/4.6/components/collapse/#accordion-example)
.accordion {
  .btn-min {
    min-width: 40px;
  }

  .card {
    box-shadow: none;
    padding: 10px 0;

    .card-header {
      padding: 0 0 0 0;
      border: 0;
      background: none;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      cursor: pointer;
      padding-right: 3px; // for nice focus outline

      .title {
        margin: 0;
        font-size: $body;
        font-weight: $font_weight_semibold;
        font-family: $font_family_semibold;
        line-height: 20px;
      }

      .btn:focus {
        box-shadow: none;
      }
    }

    .card-body {
      padding: 0 45px 0 0;
      box-shadow: none;
    }
  }
}