@import "../../styles/variables.scss";

.panel {
    background: $grey3;
    border-radius: 4px;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: row;
  
    h6 {
        font-size: $body;
        font-weight: $font_weight_semibold;
        font-family: $font_family_semibold;
        line-height: 20px;
    }
  
    a {
        color: $grey90;
        text-decoration: none;
        
        &:hover {
          text-decoration: none;
      }
    }
  }
  