@import "../../styles/variables.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.interrupt-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 15px !important;

  .interrupt-banner-container {
    display: flex;
    align-items: center;

    .car {
      height: 31px;
      width: 49px;
      min-width: 49px;
    }

    .price-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include media-breakpoint-up(sm) {
        align-items: center;
        flex-wrap: nowrap;
      }

      .price {
        font-weight: $font_weight_semibold;
        font-size: $body;
        line-height: 1.5;
      }
    }
  }
}
