@import "../../styles/variables.scss";

.car {
  width: 90px;
  height: 55px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  // background-color: $white;

  &.car--economy-hatchback {
    background-image: url("../../assets/cars/economy-hatchback.png");
  }
  &.car--economy-sedan {
    background-image: url("../../assets/cars/economy-sedan.png");
  }
  &.car--economy-van {
    background-image: url("../../assets/cars/economy-van.png");
  }
  &.car--economy-suv {
    background-image: url("../../assets/cars/economy-SUV.png");
  }
  &.car--economy-bus {
    background-image: url("../../assets/cars/economy-bus.png");
  }

  &.car--business-sedan {
    background-image: url("../../assets/cars/business-sedan.png");
  }
  &.car--business-van {
    background-image: url("../../assets/cars/business-van.png");
  }
  &.car--business-suv {
    background-image: url("../../assets/cars/business-SUV.png");
  }
  &.car--business-bus {
    background-image: url("../../assets/cars/business-bus.png");
  }
  &.car--business-limo {
    background-image: url("../../assets/cars/business-limousine.png");
  }

  &.car--luxury-sedan {
    background-image: url("../../assets/cars/luxury-sedan.png");
  }
  &.car--luxury-van {
    background-image: url("../../assets/cars/luxury-van.png");
  }
  &.car--luxury-suv {
    background-image: url("../../assets/cars/luxury-SUV.png");
  }
  &.car--luxury-bus {
    background-image: url("../../assets/cars/luxury-bus.png");
  }
  &.car--luxury-limo {
    background-image: url("../../assets/cars/luxury-limousine.png");
  }
}
