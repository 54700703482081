@import "../../styles/variables.scss";

// primary brand
.color-brand1 {
  color: $brand1 !important;
}
.color-bg-brand1 {
  background-color: $brand1 !important;
}
.color-brand2 {
  color: $brand2 !important;
}
.color-bg-brand2 {
  background-color: $brand2 !important;
}
.color-brand3 {
  color: $brand3 !important;
}
.color-bg-brand3 {
  background-color: $brand3 !important;
}
.color-brand3-tint {
  color: $brand3_tint !important;
}
.color-bg-brand3-tint {
  background-color: $brand3_tint !important;
}
.color-brand4 {
  color: $brand4 !important;
}
.color-bg-brand4 {
  background-color: $brand4 !important;
}

// highlights
.color-highlight1 {
  color: $highlight1 !important;
}
.color-bg-highlight1 {
  background-color: $highlight1 !important;
}
.color-highlight2 {
  color: $highlight2 !important;
}
.color-bg-highlight2 {
  background-color: $highlight2 !important;
}
.color-highlight3 {
  color: $highlight3 !important;
}
.color-bg-highlight3 {
  background-color: $highlight3 !important;
}
.color-highlight1-tint {
  color: $highlight1_tint !important;
}
.color-bg-highlight1-tint {
  background-color: $highlight1_tint !important;
}
.color-highlight2-tint {
  color: $highlight2_tint !important;
}
.color-bg-highlight2-tint {
  background-color: $highlight2_tint !important;
}
.color-highlight3-tint {
  color: $highlight3_tint !important;
}
.color-bg-highlight3-tint {
  background-color: $highlight3_tint !important;
}

// greyscale
.color-white {
  color: $white !important;
}
.color-bg-white {
  background-color: $white !important;
}
.color-grey-3 {
  color: $grey3 !important;
}
.color-bg-grey-3 {
  background-color: $grey3 !important;
}
.color-grey-5 {
  color: $grey5 !important;
}
.color-bg-grey-5 {
  background-color: $grey5 !important;
}
.color-grey-10 {
  color: $grey10 !important;
}
.color-bg-grey-10 {
  background-color: $grey10 !important;
}
.color-grey-20 {
  color: $grey20 !important;
}
.color-bg-grey-20 {
  background-color: $grey20 !important;
}
.color-grey-30 {
  color: $grey30 !important;
}
.color-bg-grey-30 {
  background-color: $grey30 !important;
}
.color-grey-50 {
  color: $grey50 !important;
}
.color-bg-grey-50 {
  background-color: $grey50 !important;
}
.color-grey-70 {
  color: $grey70 !important;
}
.color-bg-grey-70 {
  background-color: $grey70 !important;
}
.color-grey-80 {
  color: $grey80 !important;
}
.color-bg-grey-80 {
  background-color: $grey80 !important;
}
.color-grey-90 {
  color: $grey90 !important;
}
.color-bg-grey-90 {
  background-color: $grey90 !important;
}

// support
.color-info {
  color: $info !important;
}
.color-bg-info {
  background-color: $info !important;
}
.color-info-tint {
  color: $info_tint !important;
}
.color-bg-info-tint {
  background-color: $info_tint !important;
}
.color-success {
  color: $success !important;
}
.color-bg-success {
  background-color: $success !important;
}
.color-success-tint {
  color: $success_tint !important;
}
.color-bg-success-tint {
  background-color: $success_tint !important;
}
.color-error {
  color: $error !important;
}
.color-bg-error {
  background-color: $error !important;
}
.color-error-tint {
  color: $error_tint !important;
}
.color-bg-error-tint {
  background-color: $error_tint !important;
}
.color-warning {
  color: $warning !important;
}
.color-bg-warning {
  background-color: $warning !important;
}
.color-warning-tint {
  color: $warning_tint !important;
}
.color-bg-warning-tint {
  background-color: $warning_tint !important;
}
.color-links {
  color: $links !important;
}
.color-bg-links {
  background-color: $links !important;
}

// Data Visualisation
.color-data1 {
  color: $data1 !important;
}
.color-bg-data1 {
  background-color: $data1 !important;
}
.color-data2 {
  color: $data2 !important;
}
.color-bg-data2 {
  background-color: $data2 !important;
}
.color-data3 {
  color: $data3 !important;
}
.color-bg-data3 {
  background-color: $data3 !important;
}
.color-data4 {
  color: $data4 !important;
}
.color-bg-data4 {
  background-color: $data4 !important;
}
.color-data5 {
  color: $data5 !important;
}
.color-bg-data5 {
  background-color: $data5 !important;
}
.color-data6 {
  color: $data6 !important;
}
.color-bg-data6 {
  background-color: $data6 !important;
}
.color-data7 {
  color: $data7 !important;
}
.color-bg-data7 {
  background-color: $data7 !important;
}
.color-data8 {
  color: $data8 !important;
}
.color-bg-data8 {
  background-color: $data8 !important;
}

// Button State Colours
.color-button-primary {
  color: $btn_primary !important;
}
.color-bg-button-primary {
  background-color: $btn_primary !important;
}
.color-button-primary-hover {
  color: $btn_primary_hover !important;
}
.color-bg-button-primary-hover {
  background-color: $btn_primary_hover !important;
}
.color-button-primary-pressed {
  color: $btn_primary_pressed !important;
}
.color-bg-button-primary-pressed {
  background-color: $btn_primary_pressed !important;
}

.color-button-primary-alt {
  color: $btn_primary_alt !important;
}
.color-bg-button-primary-alt {
  background-color: $btn_primary_alt !important;
}
.color-button-primary-alt-hover {
  color: $btn_primary_alt_hover !important;
}
.color-bg-button-primary-alt-hover {
  background-color: $btn_primary_alt_hover !important;
}
.color-button-primary-alt-pressed {
  color: $btn_primary_alt_pressed !important;
}
.color-bg-button-primary-alt-pressed {
  background-color: $btn_primary_alt_pressed !important;
}

.color-button-delete {
  color: $btn_danger !important;
}
.color-bg-button-delete {
  background-color: $btn_danger !important;
}
.color-button-delete-hover {
  color: $btn_danger_hover !important;
}
.color-bg-button-delete-hover {
  background-color: $btn_danger_hover !important;
}
.color-button-delete-pressed {
  color: $btn_danger_pressed !important;
}
.color-bg-button-delete-pressed {
  background-color: $btn_danger_pressed !important;
}

.color-bg-transparent {
  background-color: transparent !important;
}