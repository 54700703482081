@import "../../styles/variables.scss";

.star-rating {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .rating {
    height: 20px;
    margin-right: 5px;
  }
  .description {
    color: $grey70;
    font-size: $caption;
    font-weight: $font_weight_normal;
  }
}
