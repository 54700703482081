@import "../../styles/variables.scss";

.card {
  border-radius: 0;
  border: 1px solid $grey10;
  background-color: $white;

  .card-title {
    text-transform: none;
    font-weight: $font_weight_normal;
    font-family: $font_family_normal;
    line-height: 1.17;
    color: $grey90;
  }

  .card-body {
    padding: 15px;
    border-radius: 0px;
  }
}
