@import "../../styles/variables.scss";

@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";

.listing-card {
  .card {
    background: $white;
  }

  .service-img {
    width: 85px;
    height: 65px;
    image-rendering: auto;
  }

  .supplier-name {
    line-height: 20px;
    font-size: 18px;
    font-weight: $font_weight_normal;
    font-family: $font_family_normal;
    color: $grey90;
    margin: 0 0 5px;
    display: inline-block;
  }

  .badges {
    text-align: center;
    margin-top: 5px;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  .price-container {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
  }
}
