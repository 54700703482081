@import "../../styles/variables.scss";

.btn {
  line-height: 20px;
  height: $btn_height;
  padding: 10px 15px;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: $font_weight_semibold;
  font-family: $font_family_normal;
  font-size: $btn_font_size;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  white-space: nowrap;

  &:focus:active,  
  &:focus {
    outline: 0;
    box-shadow: $focus_outline !important;
  }

  &[disabled],
  &.disabled {
    color: $grey30;
    border-color: $grey10;
    background-color: $white;

    .mdi {
      background-color: $grey30;
    }
  }
}

.btn-primary {
  color: $white;
  background-color: $btn_primary;

  .mdi {
    background-color: $white;
  }

  &:hover {
    background-color: $btn_primary_hover;
  }

  &:focus {
    background-color: $btn_primary_focus;
  }

  &:active, &.btn-pressed {
    background-color: $btn_primary_pressed !important;
  }

  &[disabled],
  &.disabled {
    background-color: $grey5;
  }
}

.btn-primary-alternate {
  background-color: $btn_primary_alt;
  border-color: $btn_primary_alt;
  color: $white;

  .mdi {
    background-color: $white;
  }

  &:hover {
    background-color: $btn_primary_alt_hover;
    color: $white;
  }

  &:focus {
    background-color: $btn_primary_alt_focus !important;
  }
  &:active, &.btn-pressed {
    background-color: $btn_primary_alt_pressed !important;
  }
  
  &[disabled],
  &.disabled {
    background-color: $grey5;
    &:hover{
      color: $grey30;
    }
  }
}

.btn-secondary {
  color: $btn_secondary;
  border: 2px solid $btn_secondary;
  background-color: $white;

  .mdi {
    background-color: $btn_secondary;
  }

  &:hover {
    color: $btn_secondary_hover;
    border-color: $btn_secondary_hover;
    background-color: $white;

    .mdi {
      background-color: $btn_secondary_hover;
    }
  }

  &:focus {
    color: $btn_secondary_focus;
    border-color: $btn_secondary_focus;
    background-color: $white;

    .mdi {
      background-color: $btn_secondary_focus;
    }
  }

  &:active, &.btn-pressed {
    color: $btn_secondary_pressed !important;
    border-color: $btn_secondary_pressed !important;
    background-color: $white !important;

    .mdi {
      background-color: $btn_secondary_pressed;
    }
  }

  &[disabled],
  &.disabled {
    border-color: $grey10;
    color: $grey30;
    
    .mdi {
      background-color: $grey30;
    }
  }
}

.btn-tertiary {
  color: $brand1;
  border: 1px solid $grey10;
  background-color: $white;

  .mdi {
    background-color: $brand1;
  }

  &:hover {
    background-color: $grey3;
  }

  &:focus {
    background-color: $white;
  }

  &:active, &.btn-pressed {
    background-color: $grey5;
  }

  &[disabled],
  &.disabled {
    background: $white;
  }
}

// text CTA
.btn-link {
  color: $btn_primary;
  text-transform: capitalize;
  padding: 0;
  height: auto;

  .mdi {
    background-color: $btn_primary;
  }

  &:hover {
    color: $btn_primary_hover;
    text-decoration: none;

    .mdi {
      background-color: $btn_primary_hover;
    }
  }

  &:focus {
    color: $btn_primary_hover;
    text-decoration: none;
    box-shadow: none;

    .mdi {
      background-color: $btn_primary_hover;
    }
  }
  
  &:active {
    color: $btn_primary_pressed;
    text-decoration: none;

    .mdi {
      background-color: $btn_primary_pressed;
    }
  }

  &[disabled],
  &.disabled {
    color: $grey30;
    background-color: $white;
    // border: 1px solid $grey30;

    .mdi {
      background-color: $grey30;
    }
  }
}

// TODO: do we still need this?
.cta-link {
  text-decoration: none;
  color: $links;
  border: none;
  background: transparent;
  padding: 0;

  &:hover {
    text-decoration: underline;
    color: $brand4;
  }
}

.btn-min {
  width: 40px;
  height: 40px;
  padding: 6px;
}

.btn-danger {
  border: none;
  background-color: $btn_danger;
  color: $white;

  .mdi {
    background-color: $white;
  }

  &:hover {
    background: $btn_danger_hover;
  }

  &:focus {
    background-color: $btn_danger_focus !important;
  }

  &:active {
    background-color: $btn_danger_pressed !important;
  }
  
  &:disabled {
    border-color: $btn_danger_disabled;
    background-color: $btn_danger_disabled;
    color: $grey30;

    .mdi {
      background-color: $grey30;
    }
  }
}

.btn__shadow {
  // filter: drop-shadow(0px 4px 16px rgba(24, 24, 35, 0.16));
  filter: drop-shadow($shadow_large);
}