@import "variables";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.secure-payment {
  background: url(../assets/icons/payments/security-badge.svg);
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  width: 250px;
  height: 29px;

  @include media-breakpoint-up(md) {
    width: 275px;
    height: 30px;
  }

  &.white {
    -webkit-mask-image: url(../assets/icons/payments/security-badge.svg);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
    mask-image: url(../assets/icons/payments/security-badge.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    background: none;
    background-color: $white;
  }
}

.payment-method {
  display: inline-block;
  width: 30px;
  height: 20px;

  &.payment-card {
    background: url(../assets/icons/payments/credit-card.svg);
  }

  &.paypal {
    background: url(../assets/icons/payments/paypal.svg);
  }

  &.amex {
    background: url(../assets/icons/payments/amex.svg);
  }

  &.mastercard {
    background: url(../assets/icons/payments/mastercard.svg);
  }

  &.mastercard-debit {
    background: url(../assets/icons/payments/mastercard-debit.svg);
  }

  &.visa {
    background: url(../assets/icons/payments/visa.svg);
  }

  &.visa-debit {
    background: url(../assets/icons/payments/visa-debit.svg);
  }

  &.discover {
    background: url(../assets/icons/payments/diners.svg);
  }
}

.cvv {
  display: inline-block;
  width: 30px;
  height: 20px;

  &.standard {
    background: url(../assets/icons/payments/cvv-location-standard.svg);
  }

  &.amex {
    background: url(../assets/icons/payments/cvv-location-amex.svg);
  }
}

.payment-refund-guarantee {
  -webkit-mask-image: url("../assets/icons/payments/refund-guarantee.svg");
  mask-image: url("../assets/icons/payments/refund-guarantee.svg");
  background-color: $brand1;
  width: 34px;
  height: 34px;
  display: inline-block;
  background-size: 34px 34px;
  vertical-align: middle;
}