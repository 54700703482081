@import "../../styles/variables.scss";

@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";

.modal-dialog {
  .modal-header {
    margin: 0 15px;
    padding: 15px 0;
    display: flex;
    align-items: start;
    border-bottom: none;

    .modal-title {
      line-height: 40px;
      font-size: $h3;
    }
  }

  .modal-footer {
    border: none;
  }
}

.modal-content {
  box-shadow: $shadow_medium;
  border-radius: 0px;
  border: none;
}

// slide from left popup
// https://codepen.io/bootpen/pen/jbbaRa
.modal-drawer {
  min-width: 320px; // iphone 5
  width: calc(100% - 25px);
  height: 100%;
  transition-duration: 1s;
  position: fixed;
  top: 0;
  bottom: 0;

  @include media-breakpoint-up(sm) {
    min-width: 600px;
    width: auto;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
    box-shadow: none;
  }

  .modal-content {
    border-radius: 0;
    height: 100%;

    .modal-header {
      z-index: 1;
      position: fixed;
      top: 0;
      left: 0px;
      right: 0px;
      margin: 0;
      padding: 15px;
      border-radius: 0;
      background-color: $white;

      .modal-title {
        color: $grey70;
        font-size: 24px;
      }

      .media {
        display: flex;
        align-items: center;
        width: 100%;

        .media-body {
          display: flex;
          justify-content: center;
        }
      }
    }

    .modal-body {
      padding-top: 70px;
      padding-bottom: 70px;
      overflow-y: scroll;

      .list {
        position: absolute;
        left: 0;
        right: 0;

        .list-item {
          margin: 0;
          padding: 10px 23px;
          cursor: pointer;

          &:hover {
            background-color: $grey10;
          }
        }
      }
    }

    .modal-footer {
      bottom: 0;
      position: fixed;
      left: 1px;
      right: 1px;
      background-color: $white;
      border-radius: 0;
      z-index: 3;

      * {
        margin: 0;
      }
    }
  }

  &.modal-drawer__left {
    transform: translate(-100%, 0) !important;
    margin: 0 20px 0 0;
    left: 0;

    // centered title with close button
    .media .media-body .modal-title {
      margin-left: -40px;
    }
  }
  &.modal-drawer__right {
    transform: translate(100%, 0) !important;
    margin: 0 0 0 20px;
    right: 0;

    // centered title with close button
    .media .media-body .modal-title {
      margin-right: -40px;
    }
  }
}

.modal.show > .modal-dialog.modal-drawer.modal-drawer__left,
.modal.show > .modal-dialog.modal-drawer.modal-drawer__right {
  transform: translate(0, 0) !important;
}
