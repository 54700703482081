@import "variables";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

body {
  -webkit-font-smoothing: antialiased; // requested by the product team
}

textarea,
select,
input,
button {
  outline: none;
}

textarea {
  min-height: 100px;
  resize: vertical;
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

.container {
  max-width: 970px;
}

.multiline-text {
  white-space: pre-line;
}

.list-group {
  margin: 0 -15px;

  .list-group-item {
    border-radius: 0;
    border: none;
    text-decoration: none;

    &:hover {
      background-color: $grey5;
    }

    &.active {
      background-color: $grey10;
    }

    &.active,
    &:hover {
      color: $grey90;
      background-color: $brand3_tint;
      border-color: $grey10;
    }
  }
}

.dropdown-item:active {
  background-color: $grey20;
}

.popover {
  box-shadow: $shadow_medium;
  border: none;
}

dl {
  dt {
    font-style: normal;
    font-weight: $font_weight_semibold;
    color: $grey90;
    font-size: $body;
    line-height: 20px;
    align-items: center;
    display: flex;
  }

  dd {
    font-style: normal;
    font-weight: $font_weight_normal;
    font-size: $body;
    line-height: 20px;
  }
}

.env-overlay {
  top: 30px;

  @include media-breakpoint-up(md) {
    top: 55px;
  }

  position: absolute;
  font-size: 12px;
  color: $error;
  background-color: $white;
  padding: 0 3px;
  border: 1px solid $error;
  border-radius: 3px;
  text-align: center;
  z-index: 2;
  margin-left: 50px;
}

.bottom-floating {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  border-radius: 4px;
  width: 100%;
  margin: 0 auto 15px;
  padding: 0 45px;
}

.bottom-stick {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.pl-1px {
  padding-left: 1px !important;
}

.pr-1px {
  padding-right: 1px !important;
}

.list-style-none {
  list-style: none;
}

.text-muted {
  color: $grey30 !important;
}

@include media-breakpoint-only(xs) {
  .btn-xs-block {
    width: 100%;
  }
}