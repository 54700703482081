@import "../../styles/variables.scss";

body {
  font-family: $font_family_normal;
  font-weight: $font_weight_normal;
  font-size: $body;
  overflow-x: hidden;
  background-color: $white;
  color: $grey90;
  line-height: 20px;
}

h1 {
  font-size: $h1;
  line-height: 40px;
  font-weight: $font_weight_normal;
}

h2 {
  font-size: $h2;
  line-height: 35px;
  font-weight: $font_weight_normal;
  color: $grey90;
}

h3 {
  font-size: $h3;
  line-height: 30px;
  font-weight: $font_weight_normal;
  color: $grey90;
}

h4 {
  font-size: $h4;
  line-height: 20px;
  font-weight: $font_weight_normal;
  color: $grey90;
}

h6 {
  font-size: $h4;
  font-weight: $font_weight_normal;
  font-family: $font_family_normal;
  text-transform: none;
  line-height: 1.17;
  color: $grey90;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 13px;
}

a {
  color: $links;
  text-decoration: underline;
  font-weight: $font_weight_normal;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $brand4;
    cursor: pointer;
  }

  &.caption-link {
    color: $brand1;
    font-size: $caption;
    line-height: 15px;

    &:hover,
    &:focus {
      color: $brand2;
    }
  }

  &.footer-link {
    color: $white;
    font-size: $h6;
    font-weight: $font_weight_lighter;
    line-height: 36px;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
    }
  }
}

.hero-large {
  font-family: $font_family_normal;
  font-weight: $font_weight_normal;
  font-size: $hero_large;
  line-height: 45px;
  color: $white;
}

.hero-small {
  font-family: $font_family_normal;
  font-weight: $font_weight_normal;
  font-size: $hero;
  line-height: 25px;
  color: $white;
}

.price {
  font-family: $font_family_normal;
  font-weight: $font_weight_semibold;
  font-size: $price;
  line-height: 40px;
  text-transform: uppercase;
  color: $grey90;

  &.discounted {
    font-size: $price;
    color: $error;
    height: auto;
  }

  &.original {
    font-weight: $font_weight_normal;
    line-height: 20px;
    font-size: $body;
    color: $grey70;
    text-decoration: line-through;
    height: auto;
  }
}

.caption,
label {
  font-weight: $font_weight_normal;
  font-size: $caption;
  line-height: 15px;
  color: $grey90;
}

.bold,
strong,
b {
  font-weight: $font_weight_semibold;
}

pre, code {
  color: $grey90;
  white-space: pre-line;
  font-size: $body;
}