@import "variables";

@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";

dl.dl-horizontal {
  dt {
    font-weight: $font_weight_normal;
    font-family: $font_family_normal;
    
    &.bold {
      font-weight: 600 !important;
    }
  }

}

@include media-breakpoint-up(md) {
  .dl-horizontal {
    dt {
      float: left;
      width: 150px;
      overflow: hidden;
      clear: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    dd {
      margin-left: 170px;
    }
  }
}
