@import "../../styles/variables.scss";

.form-check {
  height: 25px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin: 0 0 5px 0;

  .form-check-input {
    margin-left: -20px;
    width: 25px;
    height: 25px;
    opacity: 0;
    position: absolute;
  }

  .form-check-input ~ .form-check-label {
    margin: 0;
    color: $grey70;
    font-size: $body;
    font-weight: $font_weight_semibold;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s linear;
  }

  .form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    color: $grey30;
    cursor: default;
  }

  .form-check-input ~ .form-check-label::before {
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0;
    background-color: $grey30;
    transition: background-color 0.5s ease;
  }
  .form-check-input[type="radio"] ~ .form-check-label::before {
    -webkit-mask-image: url("../../assets/icons/radiobox-blank.svg");
    mask-image: url("../../assets/icons/radiobox-blank.svg");
  }
  .form-check-input[type="checkbox"] ~ .form-check-label::before {
    -webkit-mask-image: url("../../assets/icons/checkbox-blank-outline.svg");
    mask-image: url("../../assets/icons/checkbox-blank-outline.svg");
  }
  .form-check-input[type="radio"]:checked ~ .form-check-label::before {
    -webkit-mask-image: url("../../assets/icons/radiobox-marked.svg");
    mask-image: url("../../assets/icons/radiobox-marked.svg");
  }
  .form-check-input[type="checkbox"]:checked ~ .form-check-label::before {
    -webkit-mask-image: url("../../assets/icons/checkbox-marked.svg");
    mask-image: url("../../assets/icons/checkbox-marked.svg");
  }

  /** toggle */
  &.form-toggle {
    padding-left: 40px;

    .form-check-input {
      width: 35px;
      height: 20px;
      margin-left: -30px;
    }
    .form-check-label::before {
      width: 35px;
      height: 20px;
    }

    .form-check-input ~ .form-check-label::before {
      content: "";
      width: 35px;
      height: 20px;
      position: absolute;
      left: 0;
      background-color: $grey70;
      transition: background-color 0.5s ease;
    }

    .form-check-input[type="checkbox"] ~ .form-check-label::before {
      -webkit-mask-image: url("../../assets/icons/toggle-unselected.svg");
      mask-image: url("../../assets/icons/toggle-unselected.svg");
    }
    .form-check-input[type="checkbox"]:checked ~ .form-check-label::before {
      -webkit-mask-image: url("../../assets/icons/toggle-selected.svg");
      mask-image: url("../../assets/icons/toggle-selected.svg");
    }
  }

  
  @media (hover: hover) and (pointer: fine) {
    .form-check-input:hover ~ .form-check-label::before {
      background-color: $btn_secondary_hover;
    }
  }
  // .form-check-input:focus ~ .form-check-label::before {
  //   background-color: $brand3;
  // }
  .form-check-input:active ~ .form-check-label::before {
    background-color: $grey20;
  }

  .form-check-input:checked ~ .form-check-label::before {
    background-color: $brand3;
  }
  @media (hover: hover) and (pointer: fine) {
    .form-check-input:checked:hover ~ .form-check-label::before {
      background-color: $btn_secondary_hover;
    }
  }
  // .form-check-input:checked:focus ~ .form-check-label::before {
  //   background-color: $brand2;
  // }
  // .form-check-input:checked:active ~ .form-check-label::before {
  //   background-color: $brand2;
  // }

  .form-check-input:disabled ~ .form-check-label::before,
  .form-check-input:disabled:hover ~ .form-check-label::before,
  .form-check-input[disabled] ~ .form-check-label::before,
  .form-check-input[disabled]:hover ~ .form-check-label::before {
    background-color: $grey20;
  }
}
