
:root {
    /* overwriting some of bootstrap variables */
    --primary: #00153F;
    --secondary: #E31665;
    --success: #73DDA3;
    --info: #43D3FF;
    --warning: #F4B000;
    --danger: #FF7D76;
    --white: #fff;
    --gray: #8b8b91;
    --gray-dark: #32323d;
    --light: #f8f8f9;
    --dark: #181823;

    /* colors */
    --jr-color-brand1: #00153F;
    --jr-color-grey-90: #181823;
    --jr-color-grey-10: #e5e5e6;
    --jr-color-grey-5: #f2f2f3;
    --jr-color-white: #ffffff;
    --jr-color-support-info: #43D3FF;
    --jr-color-support-info-tint: #E3F8FF;
    --jr-color-success-tint: #EAFAF1;
    --jr-color-error-tint: #FFEBEA;
    /* font sizes */
    --jr-font-size-xx-large: 36px;
    --jr-font-size-x-large: 24px;
    --jr-font-size-large: 18px;
    --jr-font-size-body: 14px;
    --jr-font-size-small: 12px;
    --jr-font-size-x-small: 10px;
    /* font weight */
    --jr-font-weight-normal: 400;
    --jr-font-weight-bold: 600;
    /* font family */
    --jr-font-family-emails: "Arial", sans-serif;
}