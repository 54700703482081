@import "variables";

.alert {
  width: 100%;
  display: flex;
  border: none;
  padding: 10px;
  margin: 0;
  font-size: 12px;

  .mdi {
    margin-right: 5px;
    min-width: 20px;
  }

  // .alert__msg
  &__msg {
    color: $grey90;
    padding-right: 15px;
    width: 100%;
    line-height: 20px;
  }

  &.alert-neutral {
    background-color: $grey3;

    .mdi {
      background-color: $grey30;
    }
  }

  &.alert-danger {
    background-color: $error_tint;

    .mdi {
      background-color: $error;
    }
  }

  &.alert-info {
    background-color: $info_tint;

    .mdi {
      background-color: $info;
    }
  }

  &.alert-success {
    background-color: $success_tint;

    .mdi {
      background-color: $success;
    }
  }

  &.alert-warning {
    background-color: $warning_tint;

    .mdi {
      background-color: $warning;
    }
  }
}

// .btn-tertiary {
//   background-color: transparent;
//   padding: 0px;
//   height: 20px;
//   width: 20px;

//   .mdi {
//     padding: 0;
//     font-size: unset;
//     color: grey !important;
//   }
// }
