@import "./variables";

/** set class 'email' to the <body> of the email template */
.email {
    font-family: $font_family_email;
    font-weight: $font_weight_normal !important;
    font-size: $body;
    line-height: 22px;
    color: $grey90;

    h1 {
        font-size: $h1_email;
        line-height: 41px;
        font-weight: $font_weight_normal;
    }
    
    h2 {
        font-size: $h2_email;
        line-height: 34px;
    }
    
    h3 {
        font-size: $h3_email;
        line-height: 28px;
    }
    
    h4 {
        font-size: $h4_email;
        line-height: 21px;
        color: $grey90;
    }

    p {
        font-size: $body;
    }

    strong, .bold {
        font-weight: $font_weight_bold;
    }

    .a {
        color: $brand3;
        text-decoration-line: underline;
    }
}


