@import "variables";

.handy-tip {
    .card-title {
        display: flex;
        justify-content: space-between;
        font-weight: $font_weight_semibold;

        span {
            font-weight: $font_weight_semibold;
            color: $grey90;
        }

        .label {
            display: flex;
            align-items: center;
            padding: 3px 5px;
            white-space: nowrap;
            height: 20px;
            text-transform: uppercase;
            border-radius: 4px;
            font-size: $tiny;
            font-weight: $font_weight_semibold;
            color: $white;
            background-color: $highlight1;
        }
    }
}