@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "variables";

.page-wrapper {
  background-color: $grey3;
  padding-bottom: 15px;
  padding-top: 32px;

  @include media-breakpoint-up(md) {
    padding-top: 80px;
  }
  
  &.with-super-header {
      padding-top: 32px;

      @include media-breakpoint-up(md) {
        padding-top: 112px;
      }
  }

  &.with-fixed-footer {
    padding-bottom: 40px;
  }
}

