/*!
* Jayride Style #{BUILD}# | #{RELEASE}# (https://jayride.com/)
* Jayride Style {{BUILD}} | {{RELEASE}} (https://jayride.com/)
* 
* v 2.0.0
* 
* dependency: Bootstrap (https://getbootstrap.com/docs/4.6/getting-started/introduction/)
* comaptible with Bootstrap 4.*
* tested on Bootstrap 4.6
!*/
