@import "../../styles/variables.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.illustration {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  .title {
    font-size: $h2;
    margin: 15px 0 10px;

    &.notification {
      font-size: $h4;
    }
  }

  .description {
    font-size: $body;
    display: flex;
    flex-direction: column;
  }

  .illustration--img {
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    height: 105px;
    width: 105px;

    @include media-breakpoint-up(md) {
      height: 140px;
      width: 140px;
    }
  }

  .illustration-small {
    height: 80px;
  }

  .illustration-legacy {
    width: 357px;
  }

  .user-picture {
    position: absolute;
    left: calc(50% - 52px);
    top: 15px;
    width: 104px;

    @include media-breakpoint-up(md) {
      left: calc(50% - 70px);
      top: 15px;
      width: 140px;
    }
  }

  .img-nice-choice,
  .img-onwards,
  .img-ready-to-travel {
    background-image: url("../../assets/illustration/illustration-nice-choice.svg");
  }

  .img-ready-to-ride {
    background-image: url("../../assets/illustration/illustration-ready-to-ride.svg");
  }

  .img-profile {
    background-image: url("../../assets/illustration/illustration-profile.svg");
  }

  .img-bookings,
  .img-sit,
  .img-wave {
    background-image: url("../../assets/illustration/illustration-bookings.svg");
  }

  .img-review,
  .img-star {
    background-image: url("../../assets/illustration/illustration-star.svg");
  }

  .img-404 {
    background-image: url("../../assets/illustration/illustration-404.svg");
  }

  .img-icon {
    background-image: url("../../assets/illustration/illustration-icon.svg");
  }

  .img-questions {
    background-image: url("../../assets/illustration/illustration-questions.svg");
  }

  .img-zen-traveller {
    background-image: url("../../assets/illustration/illustration-zen-traveller.svg");
  }

  // legacy
  .img-luggage {
    background-image: url("../../assets/illustration/illustration-luggage.png");
  }

  .img-contact-us {
    background-image: url("../../assets/illustration/illustration-contact-us.png");
  }

  .img-clouds-and-circles {
    background-image: url("../../assets/illustration/illustration-clouds-and-circles.png");
  }

  // illustration notifications
  .img-search {
    background-image: url("../../assets/illustration/illustration-search.svg");
  }

  .img-airport {
    background-image: url("../../assets/illustration/illustration-airport.svg");
  }

  .img-filter {
    background-image: url("../../assets/illustration/illustration-filter.svg");
  }

  .img-hotel-address {
    background-image: url("../../assets/illustration/illustration-hotel-address.svg");
  }

  .img-congratulations {
    background-image: url("../../assets/illustration/congratulations.svg");
  }
  .img-email-partner {
    background-image: url("../../assets/illustration/email-partner.svg");
  }
  .img-email-support {
    background-image: url("../../assets/illustration/email-support.svg");
  }
  .img-reminder {
    background-image: url("../../assets/illustration/reminder.svg");
  }
  .img-update {
    background-image: url("../../assets/illustration/update.svg");
  }
}