@import "../../styles/variables.scss";

.mock {
  // mock skeleton animation
  @keyframes placeholderShimmer {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  .color-dark {
    background-color: $grey10 !important;
  }

  .mdi-checkbox-blank {
    background-color: $grey5 !important;
  }

  .mock-line {
    background-color: $grey5;
    height: 20px;
    margin: 0 0 5px 0;

    &.sm {
      height: 15px;
    }

    &.lg {
      height: 40px;
    }

    &.rounded {
      border-radius: 4px;
    }
  }

  .mock-img {
    background-color: $grey5;
    width: 85px;
    height: 65px;

    &.sm {
      width: 40px;
      height: 35px;
    }

    &.xl {
      height: 200px;
      width: 200px;
    }

    &.rounded {
      border-radius: 4px;
    }
  }

  .mock-circle {
    background-color: $grey5;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &.animated {
    .mdi-checkbox-blank,
    .mock-line,
    .mock-img,
    .mock-circle {
      background: linear-gradient(90deg, $grey10, $grey3);
      background-size: 200% 200%;
      animation: placeholderShimmer 2s ease infinite;
    }
  }

  .animated {
    background: linear-gradient(90deg, $grey5, $grey3);
    background-size: 200% 200%;
    animation: placeholderShimmer 2s ease infinite;
  }
}
