@import "../../styles/variables.scss";

.input-number.input-group {
    border: 1px solid $grey10;
    border-radius: 4px;

    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    height: $btn_height;

    .input-group-append,
    .input-group-prepend {
        display: flex;;

        .btn {
            width: 36px;
            height: 36px;
            border-radius: 4px;
            margin: 1px;
            border: none;
        }
    }

    .input-group-prepend {
        margin-right: -1px;
    }

    .input-group-append {
        margin-left: -1px;
    }

    input {
        background-color: transparent;
        border: 0;
        padding: 6px;
        height: 40px;
        font-size: 14px;
        display: flex;
        align-items: center;

        &:focus {
            box-shadow: none;
        }
    }

    &.disabled {
        background-color: $grey5;
        border: 1px solid $grey5;
    }
}