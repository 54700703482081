@import "../../styles/variables.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.promotion {
    border-radius: 4px;
    background-color: $brand3_tint;
    border: 2px solid $brand3;
    color: $brand1;
    
    &.activated {
      background-color: $white;
      border-color: $brand1;
      color: $highlight3;
  }

  .mdi {
    width: 50px;
    height: 50px;

    @include media-breakpoint-up(sm) {
      width: 35px;
      height: 35px;
    }
  }

  .mdi-check-circle {
    background-color: $brand1;
  }

  .mdi-sale {
    background-color: $brand3;
  }

  .media {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    .media-body {
      display: flex;
      align-items: center;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
    }
  }

  .discount-lbl {
    font-size: $h4;

    @include media-breakpoint-up(sm) {
      width: 40px;
      line-height: 20px;
    }
  }

  &.promotion-vertical {
    .mdi {
      width: 50px;
      height: 50px;
    }

    .mb-sm-0 {
      margin-bottom: 1rem !important;
    }

    .ml-sm-3 {
      margin-left: 0 !important;
    }

    .mdi-check-circle {
      margin-bottom: 0.5rem !important;
    }

    .discount-lbl {
      width: auto;
    }

    .media,
    .media-body {
      flex-direction: column !important;
    }
  }
}