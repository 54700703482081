@import "../../styles/variables.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/** hero image */

.bg--airport {
  background-image: url("../../assets/bg/airport.jpg");
}

.bg--hotel {
  background-image: url("../../assets/bg/hotel.jpg");
}

.bg--suburb {
  background-image: url("../../assets/bg/suburb.jpg");
}

.bg--home {
  background-image: url("../../assets/bg/home.jpg");
}

.bg--blue-dots {
  background-image: url("../../assets/bg/blue-dot-background.jpg");
}

.bg--red-dots {
  background-image: url("../../assets/bg/red-dot-background.jpg");
}

.bg-d-mobile-none {
  @include media-breakpoint-down(sm) {
    background-image: none;
  }
}

.bg-d-desktop-none {
  background-size: contain;

  @include media-breakpoint-up(md) {
    background-image: none;
  }
}

.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 56px 0;

  &.banner-min {
    min-height: 372px;
  }
  
  .banner--title {
    color: $white;
    font-style: normal;
    font-weight: $font_weight_normal;
    font-size: $hero_mobile;
    line-height: 36px;
    margin: 0 0 -2px 0;
    
    @include media-breakpoint-up(md) {
      line-height: 52px;
      font-size: $hero;
      margin: 0;
    }
    
    span {
      padding: 0 8px;
      background-color: $brand2;
    }
  }

  .banner-subtitle {
    margin: 4px 0 0 0;
    color: $white;
    font-style: normal;
    font-weight: $font_weight_lighter;
    font-family: $font_family_normal;
    line-height: 20px;
    font-size: $hero_mobile_subtitle;
    
    @include media-breakpoint-up(md) {
      margin: 0;
      line-height: 36px;
      font-size: $hero_subtitle;
    }
    
    span {
      padding: 0 8px;
      background-color: $brand1;
    }
  }
  
  /** for dev use and demonstration only */
  .search-box-placeholder {
    background-image: url('../../assets/bg/search-box-mobile-placeholder.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    max-width: 597px;
    height: 424px;
    
    @include media-breakpoint-up(sm) {
      height: 365px;
    }
    @include media-breakpoint-up(md) {
      background-image: url('../../assets/bg/search-box-placeholder.png');
      height: 517px;
    }

  }
}