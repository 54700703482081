@import "../../styles/variables.scss";

.table {
    .thead-light th {
        color: $grey90;
        background-color: $grey5;
        border-color: $grey5;
        vertical-align: top;
    
        .mdi {
            background-color: $grey90;
        }
    }
    
    th, td {
        vertical-align: middle;
        padding: 5px;
        border-color: $grey5;
    }

    .img-thumbnail {
        width: 50px;
        padding: 0;
    }

    // selected row
    .table-active {
        td {
            background-color: $brand3_tint;
        }
        
        &:hover td {
            background-color: $brand3_tint;
        }
    }
}

.table-hover tbody tr:hover {
    color: $grey90;
    background-color: $grey3;
}
.table-hover tbody tr:active {
    background-color: $grey5;
}
