@import "../../styles/variables.scss";

.spinner {
  .bg {
    background-image: radial-gradient(#40404b, #111118);
    bottom: 0;
    left: 0;
    opacity: 0.8;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.3s ease-in 0s;
    -webkit-transition: opacity 0.3s ease-in 0s;
    z-index: 10000;
  }

  .content {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 60px;
    position: fixed;
    z-index: 10001;
    right: 0;
    top: -60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-wrapper {
      display: inline-block;
      background: $white;
      padding: 0 10px;
      border-radius: 4px;
      margin-bottom: 15px;
    }

    .logo {
      display: block;
      margin: 0 auto;
    }

    .message {
      color: white;
      text-align: center;
      max-width: 300px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
