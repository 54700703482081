@import "../../styles/variables.scss";

.shadow-small {
  box-shadow: $shadow_small;
}

.shadow-medium {
  box-shadow: $shadow_medium;
}

.shadow-large {
  box-shadow: $shadow_large;
}
