@import "../../styles/variables.scss";

.cookie-confirmation {
    &.show {
        position: fixed;
        bottom: 0;
    }

    z-index: 1020;
    background: $grey3;
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px;

    .btn {
        white-space: nowrap;
    }
}