@import "../../styles/variables.scss";

.tab-container {
  .nav-tabs {
    border-top: 1px solid $grey10;
    border-bottom: none;

    .nav-item {
      background: $grey3;

      .nav-link {
        text-transform: capitalize;
        text-decoration: none;
        border: none;
        color: $grey70;
        font-size: $body;
        font-weight: $font_weight_semibold;
        font-family: $font_family_normal;
        height: 40px;
        padding: 10px;
        border-radius: 0;
        background: $grey3;
        margin-right: 3px;

        &:hover {
          background: $grey5;
        }
        &:active {
          background: $grey10;
        }
        &:focus {
          border-radius: 2px;
          outline: 0;
          box-shadow: $focus_outline !important;
        }
      }

      &.active .nav-link {
        background: $white;
        color: $brand1;

        &:hover {
          background: $grey5;
        }
      }
    }
  }
}

.tab-content {
}
