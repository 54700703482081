@import "../../styles/variables.scss";

.mdi {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  min-width: 20px;
  background-color: $brand1;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;

  &.mdi-round {
    border-radius: 50%;
    font-style: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// TODO: add autoprefixer to webpack

.mdi-star-half-outline {
  -webkit-mask-image: url("../../assets/icons/rating/star-half.svg");
  mask-image: url("../../assets/icons/rating/star-half.svg");
  background-color: $highlight2;
}
.mdi-star {
  -webkit-mask-image: url("../../assets/icons/rating/star.svg");
  mask-image: url("../../assets/icons/rating/star.svg");
  background-color: $highlight2;
}
.mdi-star-outline {
  -webkit-mask-image: url("../../assets/icons/rating/star-outline.svg");
  mask-image: url("../../assets/icons/rating/star-outline.svg");
  background-color: $grey30;
}

.mdi-loading {
  -webkit-mask-image: url("../../assets/icons/loading.svg");
  mask-image: url("../../assets/icons/loading.svg");
}

.mdi-account {
  -webkit-mask-image: url("../../assets/icons/account.svg");
  mask-image: url("../../assets/icons/account.svg");
}

.mdi-email {
  -webkit-mask-image: url("../../assets/icons/email.svg");
  mask-image: url("../../assets/icons/email.svg");
}

.mdi-email-outline {
  -webkit-mask-image: url("../../assets/icons/email-outline.svg");
  mask-image: url("../../assets/icons/email-outline.svg");
}

.mdi-information {
  -webkit-mask-image: url("../../assets/icons/information.svg");
  mask-image: url("../../assets/icons/information.svg");
  background-color: $info;
}
.mdi-chevron-right {
  -webkit-mask-image: url("../../assets/icons/chevron-right.svg");
  mask-image: url("../../assets/icons/chevron-right.svg");
}
.mdi-chevron-double-right {
  -webkit-mask-image: url("../../assets/icons/chevron-double-right.svg");
  mask-image: url("../../assets/icons/chevron-double-right.svg");
}
.mdi-chevron-left {
  -webkit-mask-image: url("../../assets/icons/chevron-left.svg");
  mask-image: url("../../assets/icons/chevron-left.svg");
}
.mdi-chevron-double-left {
  -webkit-mask-image: url("../../assets/icons/chevron-double-left.svg");
  mask-image: url("../../assets/icons/chevron-double-left.svg");
}
.mdi-chevron-up {
  -webkit-mask-image: url("../../assets/icons/chevron-up.svg");
  mask-image: url("../../assets/icons/chevron-up.svg");
}
.mdi-chevron-up-outline {
  -webkit-mask-image: url("../../assets/icons/chevron-up-outline.svg");
  mask-image: url("../../assets/icons/chevron-up-outline.svg");
}
.mdi-chevron-down {
  -webkit-mask-image: url("../../assets/icons/chevron-down.svg");
  mask-image: url("../../assets/icons/chevron-down.svg");
}
.mdi-chevron-down-outline {
  -webkit-mask-image: url("../../assets/icons/chevron-down-outline.svg");
  mask-image: url("../../assets/icons/chevron-down-outline.svg");
}
.mdi-circle-outline {
  -webkit-mask-image: url("../../assets/icons/circle-outline.svg");
  mask-image: url("../../assets/icons/circle-outline.svg");
}
.mdi-check-circle {
  -webkit-mask-image: url("../../assets/icons/check-circle.svg");
  mask-image: url("../../assets/icons/check-circle.svg");
  background-color: $success;
}
.mdi-sale {
  -webkit-mask-image: url("../../assets/icons/sale.svg");
  mask-image: url("../../assets/icons/sale.svg");
  background-color: $white;
}
.mdi-alert {
  -webkit-mask-image: url("../../assets/icons/alert.svg");
  mask-image: url("../../assets/icons/alert.svg");
}
.mdi-close-circle {
  -webkit-mask-image: url("../../assets/icons/close-circle.svg");
  mask-image: url("../../assets/icons/close-circle.svg");
  background-color: $error;
}
.mdi-close {
  -webkit-mask-image: url("../../assets/icons/close.svg");
  mask-image: url("../../assets/icons/close.svg");
}
.mdi-menu {
  -webkit-mask-image: url("../../assets/icons/menu.svg");
  mask-image: url("../../assets/icons/menu.svg");
}
.mdi-lock {
  -webkit-mask-image: url("../../assets/icons/lock.svg");
  mask-image: url("../../assets/icons/lock.svg");
}

.mdi-trash {
  -webkit-mask-image: url("../../assets/icons/trash.svg");
  mask-image: url("../../assets/icons/trash.svg");
}

.mdi-map-marker {
  -webkit-mask-image: url("../../assets/icons/map-marker.svg");
  mask-image: url("../../assets/icons/map-marker.svg");
}
.mdi-airplane {
  -webkit-mask-image: url("../../assets/icons/airplane.svg");
  mask-image: url("../../assets/icons/airplane.svg");
}
.mdi-clock, .mdi-clock-outline {
  -webkit-mask-image: url("../../assets/icons/clock.svg");
  mask-image: url("../../assets/icons/clock.svg");
}
.mdi-calendar {
  -webkit-mask-image: url("../../assets/icons/calendar.svg");
  mask-image: url("../../assets/icons/calendar.svg");
}

.mdi-magnify,
.mdi-search {
  -webkit-mask-image: url("../../assets/icons/magnify.svg");
  mask-image: url("../../assets/icons/magnify.svg");
}

.mdi-home-outline {
  -webkit-mask-image: url("../../assets/icons/home-outline.svg");
  mask-image: url("../../assets/icons/home-outline.svg");
}

.mdi-tag-heart {
  -webkit-mask-image: url("../../assets/icons/tag-heart.svg");
  mask-image: url("../../assets/icons/tag-heart.svg");
}

.mdi-unfold-more-horizontal {
  -webkit-mask-image: url("../../assets/icons/unfold-more-horizontal.svg");
  mask-image: url("../../assets/icons/unfold-more-horizontal.svg");
}

.mdi-download {
  -webkit-mask-image: url("../../assets/icons/download.svg");
  mask-image: url("../../assets/icons/download.svg");
}

.mdi-share-variant {
  -webkit-mask-image: url("../../assets/icons/share-variant.svg");
  mask-image: url("../../assets/icons/share-variant.svg");
}

.mdi-tune {
  -webkit-mask-image: url("../../assets/icons/tune.svg");
  mask-image: url("../../assets/icons/tune.svg");
}

.mdi-settings {
  -webkit-mask-image: url("../../assets/icons/settings.svg");
  mask-image: url("../../assets/icons/settings.svg");
}

.mdi-arrow-up-down {
  -webkit-mask-image: url("../../assets/icons/arrow-up-down.svg");
  mask-image: url("../../assets/icons/arrow-up-down.svg");
}

.mdi-earth {
  -webkit-mask-image: url("../../assets/icons/earth.svg");
  mask-image: url("../../assets/icons/earth.svg");
}

.mdi-refresh {
  -webkit-mask-image: url("../../assets/icons/refresh.svg");
  mask-image: url("../../assets/icons/refresh.svg");
}

.mdi-code-tags {
  -webkit-mask-image: url("../../assets/icons/code-tags.svg");
  mask-image: url("../../assets/icons/code-tags.svg");
}

.mdi-eye {
  -webkit-mask-image: url("../../assets/icons/eye.svg");
  mask-image: url("../../assets/icons/eye.svg");
}

.mdi-send {
  -webkit-mask-image: url("../../assets/icons/send.svg");
  mask-image: url("../../assets/icons/send.svg");
}

.mdi-launch {
  -webkit-mask-image: url("../../assets/icons/launch.svg");
  mask-image: url("../../assets/icons/launch.svg");
}

.mdi-swap-horizontal {
  -webkit-mask-image: url("../../assets/icons/swap-horizontal.svg");
  mask-image: url("../../assets/icons/swap-horizontal.svg");
}

.mdi-swap-vertical {
  -webkit-mask-image: url("../../assets/icons/swap-vertical.svg");
  mask-image: url("../../assets/icons/swap-vertical.svg");
}

.mdi-bed, .mdi-hotel {
  -webkit-mask-image: url("../../assets/icons/hotel.svg");
  mask-image: url("../../assets/icons/hotel.svg");
}

.mdi-sort {
  -webkit-mask-image: url("../../assets/icons/sort.svg");
  mask-image: url("../../assets/icons/sort.svg");
}

.mdi-map-marker-distance {
  -webkit-mask-image: url("../../assets/icons/map-marker-distance.svg");
  mask-image: url("../../assets/icons/map-marker-distance.svg");
}

.mdi-flask {
  -webkit-mask-image: url("../../assets/icons/flask.svg");
  mask-image: url("../../assets/icons/flask.svg");
}

.mdi-drag {
  -webkit-mask-image: url("../../assets/icons/drag.svg");
  mask-image: url("../../assets/icons/drag.svg");
}

.mdi-plus {
  -webkit-mask-image: url("../../assets/icons/plus.svg");
  mask-image: url("../../assets/icons/plus.svg");
}

.mdi-minus {
  -webkit-mask-image: url("../../assets/icons/minus.svg");
  mask-image: url("../../assets/icons/minus.svg");
}

.mdi-airplane-landing {
  -webkit-mask-image: url("../../assets/icons/airplane-landing.svg");
  mask-image: url("../../assets/icons/airplane-landing.svg");
}
.mdi-airplane-takeoff {
  -webkit-mask-image: url("../../assets/icons/airplane-takeoff.svg");
  mask-image: url("../../assets/icons/airplane-takeoff.svg");
}

.mdi-file-document {
  -webkit-mask-image: url("../../assets/icons/file-document.svg");
  mask-image: url("../../assets/icons/file-document.svg");
}

.mdi-cancel {
  -webkit-mask-image: url("../../assets/icons/cancel.svg");
  mask-image: url("../../assets/icons/cancel.svg");
}

.mdi-help-circle {
  -webkit-mask-image: url("../../assets/icons/help-circle.svg");
  mask-image: url("../../assets/icons/help-circle.svg");
}

.mdi-cellphone-iphone {
  -webkit-mask-image: url("../../assets/icons/cellphone-iphone.svg");
  mask-image: url("../../assets/icons/cellphone-iphone.svg");
}

.mdi-phone {
  -webkit-mask-image: url("../../assets/icons/phone.svg");
  mask-image: url("../../assets/icons/phone.svg");
}

.mdi-phone-outline {
  -webkit-mask-image: url("../../assets/icons/phone-outline.svg");
  mask-image: url("../../assets/icons/phone-outline.svg");
}

.mdi-arrow-up {
  -webkit-mask-image: url("../../assets/icons/arrow-up.svg");
  mask-image: url("../../assets/icons/arrow-up.svg");
}

.mdi-arrow-down {
  -webkit-mask-image: url("../../assets/icons/arrow-down.svg");
  mask-image: url("../../assets/icons/arrow-down.svg");
}

.mdi-credit-card-check-outline {
  -webkit-mask-image: url("../../assets/icons/credit-card-check-outline.svg");
  mask-image: url("../../assets/icons/credit-card-check-outline.svg");
}

.mdi-credit-card {
  -webkit-mask-image: url("../../assets/icons/credit-card.svg");
  mask-image: url("../../assets/icons/credit-card.svg");
}

.mdi-checkbox-marked {
  -webkit-mask-image: url("../../assets/icons/checkbox-marked.svg");
  mask-image: url("../../assets/icons/checkbox-marked.svg");
}

.mdi-checkbox-blank-outline {
  -webkit-mask-image: url("../../assets/icons/checkbox-blank-outline.svg");
  mask-image: url("../../assets/icons/checkbox-blank-outline.svg");
}

.mdi-table-of-contents {
  -webkit-mask-image: url("../../assets/icons/table-of-contents.svg");
  mask-image: url("../../assets/icons/table-of-contents.svg");
}

.mdi-transfer {
  -webkit-mask-image: url("../../assets/icons/transfer.svg");
  mask-image: url("../../assets/icons/transfer.svg");
}

.mdi-glob {
  -webkit-mask-image: url("../../assets/icons/glob.svg");
  mask-image: url("../../assets/icons/glob.svg");
}

.mdi-facebook,
.mdi-twitter,
.mdi-instagram,
.mdi-linkedin {
  width: 30px !important;
  height: 30px !important;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-size: contain;
}

.mdi-facebook {
  -webkit-mask-image: url("../../assets/icons/social-networks/facebook.svg");
  mask-image: url("../../assets/icons/social-networks/facebook.svg");
}
.mdi-twitter {
  -webkit-mask-image: url("../../assets/icons/social-networks/twitter.svg");
  mask-image: url("../../assets/icons/social-networks/twitter.svg");
}
.mdi-instagram {
  -webkit-mask-image: url("../../assets/icons/social-networks/instagram.svg");
  mask-image: url("../../assets/icons/social-networks/instagram.svg");
}
.mdi-linkedin {
  -webkit-mask-image: url("../../assets/icons/social-networks/linkedin.svg");
  mask-image: url("../../assets/icons/social-networks/linkedin.svg");
}

.mdi-pin-airport,
.mdi-pin-hotel,
.mdi-pin-filled {
  width: 48px;
  height: 48px;
  background: transparent;
  background-size: contain;
  background-repeat: no-repeat;
}
.mdi-pin-airport {
  background-image: url("../../assets/icons/map-markers/pin-airport.png");
}
.mdi-pin-hotel {
  background-image: url("../../assets/icons/map-markers/pin-hotel.png");
}
.mdi-pin-filled {
  background-image: url("../../assets/icons/map-markers/pin-filled.png");
}

// utilities

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.mdi-spin {
  animation: mdi-spin 2s infinite linear;
}

.mdi-rotate-90 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
