@import "../../styles/variables.scss";

.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 0;

  margin: 0;
  padding: 16px 0;
  background-color: $brand1;
  letter-spacing: 1px;

  .breadcrumb-item {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    color: $brand3;
    font-weight: $font_weight_bolder;
    font-size: $caption;
    line-height: 16px;
    text-transform: uppercase;

    &::before {
      padding: 0 8px;
      width: 24px;
      height: 16px;
      color: $brand3;
      font-weight: $font_weight_bolder;
      font-size: $caption;
      line-height: 16px;
      content: "//";
    }

    &:first-child::before {
      content: none;
      float: none;
    }

    a {
      text-decoration: none;
      color: $brand3;
      font-weight: $font_weight_bolder;
      font-size: $caption;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: $brand3;
      }
    }
  }
}
