@import "../../styles/variables.scss";

.banner-usp {
    background: rgba(0, 21, 63, 0.75);
    border-radius: 80px;
    padding: 2px 32px;
    color: $white;
    display: flex;
    width: fit-content;
    font-size: $h4;
    line-height: 24px;
    font-style: normal;
    font-family: $font_family_normal;

    strong {
        font-weight: $font_weight_semibold;
    }
    span {
        font-weight: $font_weight_lighter;
    }

    .banner-usp--icon {
        display: block;
        background-color: $brand1;
        width: 64px;
        height: 64px;
        border-radius: 64px;
        margin-left: -32px;
        margin-right: 24px;
        background-image: url("../../assets/icons/thumb-up.svg");
        background-repeat: no-repeat;
        background-position: center;
    }
}