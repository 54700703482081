@import "../../styles/variables.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.tooltip {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    box-shadow: $shadow_medium;
  }
}

.tooltip {
  .tooltip-inner {
    background-color: $info_tint;
    color: $grey90;
    // box-shadow: $shadow_medium;
    box-shadow: $shadow_small;
  }

  &.bs-tooltip-top {
    .arrow:before {
      border-top-color: $info_tint;
      z-index: 1;
    }
  }

  &.bs-tooltip-bottom {
    .arrow:before {
      border-bottom-color: $info_tint;
    }
  }

  &.bs-tooltip-right {
    .arrow:before {
      border-right-color: $info_tint;
    }
  }

  &.bs-tooltip-left {
    .arrow:before {
      border-left-color: $info_tint;
    }
  }
}

// tooltip with rich html content
.tooltip-html .tooltip-inner {
  max-width: 300px;
  position: relative;
  left: auto;
  
  @include media-breakpoint-up(md) {
    left: 122px;
  }
}