@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../styles/variables.scss";

footer {
  background-color: $brand1;
  padding: 36px 0;
  @include media-breakpoint-up(md) {
    padding: 56px 0;
  }
  font-size: $body;
  color: $white;
  font-weight: $font_weight_lighter;

  // footer fixed to the bottom (always displayed)
  &.footer--fixed-bottom {
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  // small footer for internal products
  &.footer--light {
    min-height: 50px;
    text-align: center;
    padding: 16px;
    font-weight: 300;
  }

  .copyright-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    i {
      height: 31px;
      width: 124px;
    }

    .copyright {
      font-weight: $font_weight_lighter;
      font-size: $tiny;
      line-height: 12px;

      padding-top: 24px;
      @include media-breakpoint-up(md) {
        padding-top: 0;
      }
    }
  }

  .social-networks-title {
    color: $white;
    font-weight: $font_weight_lighter;
    font-size: $h6;
    line-height: 36px;
    margin: 0;
  }

  .social-networks {
    padding: 52px 0 0 0;

    @include media-breakpoint-up(md) {
      padding: 12px 0;
    }

    a {
      width: 20px;
      height: 20px;
      display: inline-block;
      background-position: center;
      background-repeat: no-repeat;
    }

    a.ml {
      margin-left: 36px;
    }

    a.facebook {
      background-image: url(../../assets/icons/social-networks/facebook.svg);
    }

    a.linkedin {
      background-image: url(../../assets/icons/social-networks/linkedin.svg);
    }

    a.twitter {
      background-image: url(../../assets/icons/social-networks/twitter.svg);
    }

    a.instagram {
      background-image: url(../../assets/icons/social-networks/instagram.svg);
    }

    a.pinterest {
      background-image: url(../../assets/icons/social-networks/pinterest.svg);
    }
  }

  a {
    display: block;
  }

  .pb {
    padding-bottom: 56px;
  }
}