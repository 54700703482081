@import "./css-variables";

// ============= COLOURS ============

// Brand
$brand1: #00153F;
$brand2: #E31665;
$brand3: #43D3FF;
$brand3_tint: #E3F8FF;
$brand4: #4E617C;

// Highlights
$highlight1: #43D3FF;
$highlight1_tint: #E3F8FF;
$highlight2: #F4B000;
$highlight2_tint: #FEF7E5;
$highlight3: #00153F;
$highlight3_tint: #D9DCE2;

// Data visualisation
$data1: $brand1;
$data2: #22749F;
$data3: $brand3;
$data4: #5BD7C1;
$data5: #FFDB40;
$data6: $highlight2;
$data7: #FF754D;
$data8: #F3435F;

// Greyscale
$grey90: #181823;
$grey80: #32323d;
$grey70: #4b4b55;
$grey50: #8b8b91;
$grey30: #b2b2b6;
$grey20: #cccccf;
$grey10: #e5e5e6;
$grey5: #f2f2f3;
$grey3: #f8f8f9;
$white: #ffffff;

// Button States
$btn_primary: $brand1;
$btn_primary_hover: #16547F;
$btn_primary_pressed: #2D94BF;
$btn_primary_focus: #16547F;
$btn_primary_disabled: $grey5;

$btn_primary_alt: #E31665;
$btn_primary_alt_hover: #BD165F;
$btn_primary_alt_pressed: #721652;
$btn_primary_alt_focus: #BD165F;
$btn_primary_alt_disabled: $grey5;

$btn_secondary: $brand1;
$btn_secondary_hover: #16547F;
$btn_secondary_pressed: #2D94BF;
$btn_secondary_focus: $brand1;
$btn_secondary_disabled: $grey30;

$btn_danger: #FF7D76;
$btn_danger_hover: #FF4D43;
$btn_danger_pressed: #FF1C10;
$btn_danger_focus: #FF4D43;
$btn_danger_disabled: $grey5;

$btn_tertiary: $grey10;
$btn_tertiary_hover: $grey3;
$btn_tertiary_pressed: $grey5;
$btn_tertiary_focus: $grey3;
$btn_tertiary_disabled: $grey30;

$btn_rating: #F4B000;
$btn_rating_hover: #FBCC00;
$btn_rating_pressed: #D9B000;

// Support
$info: #43D3FF;
$info_tint: #E3F8FF;
$success: #73DDA3;
$success_tint: #EAFAF1;
$error: #FF7D76;
$error_tint: #FFEBEA;
$warning: #F4B000;
$warning_tint: #FEF7E5;
$links: #3476AF;

// UI Colours
$btn_warning_hover: #e7ba04;
$promotion: #f64a28;
$promotion_activated: #52397f;

// ============= SHADOWS ============
$shadow_small: 0px 2px 5px rgba(24, 24, 35, 0.08);
$shadow_medium: 0px 2px 8px rgba(24, 24, 35, 0.16);
$shadow_large: 0px 4px 16px rgba(24, 24, 35, 0.16);

$focus_outline: 0 0 0 0.2rem rgba(24, 24, 35, 0.1);

// ============= FONTS ============
$font_weight_lighter: 300;
$font_weight_normal: 400;
$font_weight_semibold: 600;
$font_weight_bold: 700;
$font_weight_bolder: 800;
$font_family_normal: "Roboto", sans-serif;
$font_family_semibold: "Roboto-Medium", "Roboto", sans-serif;
$font_family_light: "Roboto-Light", "Roboto", sans-serif;
$font_family_email: "Arial", sans-serif;

// ============= SIZES ============
$btn_height: 40px;
$btn_font_size: 14px;

$form_control_error_font_size: 12px;
$form_control_font_size: 14px;
$form_control_height: 40px;

/** @deprecated */
$hero_large: 45px;

$hero: 40px;
$hero_mobile: 26px;
$hero_subtitle: 26px;
$hero_mobile_subtitle: 16px;

$h1: 36px;
$h2: 30px;
$h3: 24px;
$h4: 18px;
$h6: 16px;
$body_reskin: 16px;
$body: 14px;
$caption: 12px;
$tiny: 10px;
$price: 30px;

$h1_email: 36px;
$h2_email: 30px;
$h3_email: 24px;
$h4_email: 18px;