@import "../../styles/variables.scss";

@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";

.search-widget {
    background-color: $white;
    box-shadow: 0px 5px 3px rgba(0, 21, 63, 0.1);

    .form-control {
        color: $grey90;
        
        &:read-only {
            background-color: $white;
        }
        
        &[disabled] {
            background-color: $grey5;
        }
    }


    .form-control-with-icon {
        .mdi {
            background-color: $brand1;
        }

        &.disabled .mdi {
            background-color: $grey30;
        }

        .btn {
            position: absolute;
            right: 3px;
            height: 34px;
            width: 34px;
            padding: 0;
            border: none;
        }
    }

    .form-check {
        position: absolute;
        bottom: 17px;

        .form-check-label:before {
            height: 40px;
            width: 40px;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center;
            //     -webkit-mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            //     mask-size: contain;
        }
    }

    .swap-locations {
        position: absolute;
        top: calc(50% - 14px);
        right: 15px;
        left: auto;

        @include media-breakpoint-up(md) {
            left: calc(50% - 20px);
            top: 26px;
        }
    }

    .submit-container {
        padding-top: 0;

        @include media-breakpoint-up(md) {
            padding-top: 26px;
        }
    }

    .position-relative {
        .pr-4 {
            padding-right: 15px !important;

            @include media-breakpoint-up(md) {
                padding-right: 25px !important;
            }
        }

        .pl-4 {
            padding-left: 15px !important;

            @include media-breakpoint-up(md) {
                padding-left: 25px !important;
            }
        }
    }
}